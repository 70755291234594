<template>
  <div class="BaseInfo">
    <div class="navC">基本信息管理</div>
    <div class="baseInfo-main">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px" label-position="top" class="demo-ruleForm">
        <div class="mainBox public-form-studioAdmin">
          <div class="leftBox">
            <div class="topImg">
              <img :src="form.photo_url" />
            </div>
            <el-popover placement="bottom" width="200" trigger="click">
              <div class="bomImg" slot="reference">
                更改头像/logo
              </div>

              <div class="setImg">
                <h4>选择头像：</h4>
                <div class="imgList">
                  <div :class="activeClass1 == index ? 'box_img1' : 'box_img2'" @click="selecttwoPhoto(index, item)"
                    v-for="(item, index) in select2Photo" :key="index">
                    <img :src="item.photo_url" alt="" />
                  </div>
                </div>
                <h4>上传头像：</h4>
                <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi"
                  :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="isUpOk">
                  <div class="jh">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </dc-upload>
              </div>
            </el-popover>
          </div>

          <div class="rightBox">
            <el-form-item label="工作室名称" prop="teaching_studio_name">
              <el-input v-model="form.teaching_studio_name" style="width: 453px"></el-input>
            </el-form-item>

            <el-form-item label="工作室背景" prop="background">
              <div class="toCenter">
                <div :class="activeClass0 == index ? 'box_img1' : 'box_img2'" class="cursor"
                  @click="selectOnePhoto(index, item.photo_url)" v-for="(item, index) in selectPhoto" :key="index">
                  <img class="img_cover" :src="item.photo_url" alt="" />
                  <i class="el-icon-check dg"></i>
                </div>
              </div>
              <!-- end -->
              <div style="border: 1px"></div>
            </el-form-item>

            <el-form-item label="主持人头衔/职称" prop="label">
              <el-input v-model="form.label" @input="labelinput" style="width: 453px"></el-input>
              <span class="ml10" style="color: #999;">有多个的可用逗号隔开</span>
            </el-form-item>

            <el-form-item label="学段学科" prop="stage_id">
              <el-select v-model="form.stage_id" placeholder="学段" class="sid" style="margin-right: 20px">
                <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id"
                  @click.native="getSubject(item.id)">
                </el-option>
              </el-select>
              <el-select v-model="form.subject_id" placeholder="学科" class="sid">
                <el-option v-for="item in optiones1" :key="item.subjectId" :label="item.subjectName"
                  :value="item.subjectId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设立单位">
              <el-input v-model="form.organization_name" disabled style="width: 400px;">
                <el-button type="text" slot="suffix" @click="openorg">修改单位</el-button>
              </el-input>
              <!-- <p style="display: flex; align-items: center">
                <el-tag type="success" v-if="form.organization_name">{{
                  form.organization_name
                }}</el-tag>
                <span @click="openorg" style="margin-left: 10px; color: #3489ff; cursor: pointer">修改单位</span>
              </p> -->
            </el-form-item>

            <el-form-item label="主持人简介" prop="teacher_synopsis">
              <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="form.teacher_synopsis" resize="none"
                style="width: 700px">
              </el-input>
            </el-form-item>
            <el-form-item label="工作室简介" prop="studio_synopsis">
              <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="form.studio_synopsis" resize="none"
                style="width: 700px">
              </el-input>
            </el-form-item>
            <el-form-item label="工作室等级/类型">
              {{ form.provinces_cities_counties == 1 ? '省级' : form.provinces_cities_counties == 2 ? '市级' : '区/县级' }}{{ form.host_type_str }}
            </el-form-item>
            <el-form-item label="能否申请加入" prop="apply_join">
              <el-radio-group v-model="form.apply_join">
                <el-radio v-for="item in radioList" :key="item.id" :value="item.id" :label="item.id">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('form')">保存</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <el-dialog title="修改单位" :visible.sync="tableVisible" top="5vh">
      <el-form style="margin-top: 0;" :inline="true" :model="orgObj" class="demo-form-inline">
        <el-form-item label="">
          <el-input v-model="orgObj.search_name" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="List" border>
        <el-table-column label="选择" width="50">
          <template slot-scope="scope">
            <el-radio-group v-model="form.organization_id">
              <el-radio :label="scope.row.id" @click.native="selectOne(scope.$index)"><span></span></el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column property="name" label="单位名称" width="250"></el-table-column>
        <el-table-column property="type_name" label="单位类别"></el-table-column>
        <el-table-column property="area" label="所属地区"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block" style="text-align: center; margin-top: 20px">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="orgObj.page"
          :page-size="orgObj.limit" :page-sizes="[5, 10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div class="btn" style="text-align: center; padding-top: 20px">
        <el-button type="primary" @click="addPlace()">确定</el-button>
        <el-button @click="tableVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <div class="head-border"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      List: [],
      tableVisible: false,
      radio2: "",
      //表单数据源
      form: {
        id: "",
        photo_id: "",
        photo_url: "",
        background: "",
        user_id: "",
        teaching_studio_name: "",
        label: "",
        stage_id: "",
        subject_id: "",
        organization_id: "",
        teacher_synopsis: "",
        studio_synopsis: "",
        apply_join: "",
      },
      radioList: [
        {
          id: 1,
          name: "能",
        },
        {
          id: 2,
          name: "否",
        },
      ],
      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      value: "",
      textarea: "",
      //控制三张图变量
      activeClass0: 0,
      activeClass1: 0,
      // 三张图片数组
      selectPhoto: [
        {
          id: 5886214,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/cusn/20211008/3d068552-0968-4544-9578-0761bc0e9851.png",
        },
        {
          id: 5886215,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/cusn/20211008/80578201-732d-41e3-9e89-5afebf778e71.png",
        },
        {
          id: 5886216,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/cusn/20211008/f6ca363a-7908-49aa-acc9-7f27351751e8.png",
        },
        {
          id: 5886217,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/cusn/20211008/9d734600-2f54-433c-9977-53464946571c.png",
        },
      ],
      select2Photo: [
        {
          id: 5886498,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/69961e47-6225-423d-b8dd-e69739dff34c.png"
        },
        {
          id: 5886497,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/0d0b72c0-4fd5-46b7-a51c-4e3e78dac58e.png"
        },
        {
          id: 5886496,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/e9c143e2-6a7b-4f1d-a728-c1747096a51f.png"
        },
        {
          id: 5886495,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/cab4ad90-afec-49ff-a503-a3fbbc28a8a3.png"
        },
        {
          id: 5886844,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/377ce073-4518-41b5-8fc8-1d113e574e66.png"
        },
        {
          id: 5886493,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20210113/d43394a1-357d-48a6-9ad4-e9da5e4a6982.png"
        }
      ],
      //学段
      optiones: [],
      //学科
      optiones1: [],

      rules: {
        photo_url: [
          { required: true, message: "请上传工作室头像", trigger: "blur" },
        ],
        background: [
          { required: true, message: "请选择工作室背景", trigger: "change" },
        ],
        teaching_studio_name: [
          { required: true, message: "请填写工作室名", trigger: "change" },
        ],
        label: [
          { required: true, message: "请填写工作室标签", trigger: "change" },
        ],
        stage_id: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        subject_id: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        organization_id: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
        teacher_synopsis: [
          { required: true, message: "请填写主持人简介", trigger: "blur" },
        ],
        studio_synopsis: [
          { required: true, message: "请填写工作室简介", trigger: "blur" },
        ],
        apply_join: [
          { required: true, message: "请填选择是否加入", trigger: "blur" },
        ],
      },
      orgObj: {
        page: 1,
        limit: 10,
        search_name: "",
      },
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    // 初始化金山云地址
    this._getAddress();
    //获取详情信息
    this.getBaseInfo();
    //获取学段
    this.getPhase();
  },
  computed: {},
  methods: {
    openorg() {
      this.List = [];
      this.total = 0;
      this.orgObj = {
        page: 1,
        limit: 10,
        search_name: "",
      }
      this.getORG();
    },
    // 单选事件
    selectOne(id) {
      //  选中的id
      this.radio2 = id;
    },
    onSubmit() {
      this.orgObj.page = 1
      this.getORG()
    },
    //获取单位
    async getORG() {
      let res = await this.$axios.get("/organization", {
        params: this.orgObj,
      });
      this.List = res.data.data.data;
      this.total = res.data.data.total;
      this.tableVisible = true
      //提取id
      //   let arr = res.data.data.data;
      //   this.radioList1 = arr.map((item) => {
      //     return item.id;
      //   });
    },

    //添加地址
    addPlace() {
      let idx = this.radio2;
      this.form.organization_name = this.List[idx].name;
      this.tableVisible = false;
    },

    //单位分页
    handleSizeChange(val) {
      this.orgObj.limit = val;
      this.getORG();
    },
    handleCurrentChange(val) {
      this.orgObj.page = val;
      this.getORG();
    },
    labelinput() {
      this.form.label = this.form.label.replace('，', ',')
    },
    //获取编辑详情
    async getBaseInfo() {
      let res = await this.$axios.get(
        "index/TeachingStudio/TeachingStudioInfo",
        {
          params: {
            id: localStorage.getItem("studioId"),
            user_id: localStorage.getItem("id"),
          },
        }
      );
      this.form = res.data.data;
      this.form.photo_url = this.form.photo_url ? this.form.photo_url : this.selectPhoto[0].photo_url
      this.selectPhoto.forEach((item, index) => {
        if (this.form.background == item.photo_url) {
          this.activeClass0 = index;
        }
      });
      this.activeClass1 = 6
      this.select2Photo.forEach((item, index) => {
        if (this.form.photo_id == item.id) {
          this.activeClass1 = index;
        }
      });
      this.getSubject(this.form.stage_id);
    },
    //选择第一张图片（完成）
    selectOnePhoto(index, urlImg) {
      // index为数组下标----
      //  赋值操作
      this.activeClass0 = index;
      //得到id
      this.form.background = urlImg;
    },
    selecttwoPhoto(index, item) {
      this.activeClass1 = index;
      this.form.photo_url = item.photo_url;
      this.form.photo_id = item.id;
    },
    //表单提交
    submitForm(form) {
      let {
        photo_id,
        background,
        user_id,
        teaching_studio_name,
        label,
        stage_id,
        subject_id,
        organization_id,
        teacher_synopsis,
        studio_synopsis,
        apply_join,
        photo_url,
      } = this.form;
      let obj = {
        id: localStorage.getItem("studioId"),
        photo_id: photo_id,
        background: background.toString(),
        user_id: user_id,
        teaching_studio_name: teaching_studio_name,
        label: label,
        stage_id: stage_id,
        subject_id: subject_id,
        organization_id: organization_id,
        teacher_synopsis: teacher_synopsis,
        studio_synopsis: studio_synopsis,
        apply_join: apply_join,
        photo_url: photo_url,
      };

      this.$axios
        .put("index/TeachingStudio/editTeachingStudio", obj)
        .then(() => {
          this.$message.success("编辑成功");
          //获取详情信息
          this.getBaseInfo();
          this.$emit("getAdminData");
        });
    },

    //获取学段
    async getPhase() {
      let res = await this.$axios.get("stage/stage_list");
      this.optiones = res.data.data;
    },

    //获取学科
    async getSubject(pid) {
      let res = await this.$axios.get("subject/list", {
        params: {
          pharseid: pid,
        },
      });
      this.optiones1 = res.data.data;
    },

    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      // 上传成功就关掉
      this.isUpOk = false;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });

      this.activeClass1 = 6;
      this.form.photo_url = resData.Location;
      this.form.photo_id = res.data.data.id;
      this.isUpOk = true;
    },
  },
};
</script>
<style lang="less" scoped>
.BaseInfo {
  width: 1106px;
  border-radius: 4px;
  background: url('../../assets/images/studioHome/index_bg.png') no-repeat;
  background-size: 100%;
  position: relative;

  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    position: relative;
    z-index: 1;
  }

  .mainBox {
    display: flex;
    padding: 20px 40px;
    justify-content: space-between;

    .leftBox {
      width: 152px;

      .topImg {
        width: 152px;
        height: 152px;
        background: #e5edf2;
        border-radius: 8px 8px 0 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }
      }

      .bomImg {
        width: 152px;
        height: 40px;
        background: rgba(82, 184, 118, 1);
        border-radius: 0 0 8px 8px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }


    }

    .rightBox {
      width: calc(100% - 192px);
    }
  }

  // 直播封面样式
  .toCenter {
    display: flex;

    //三张图片样式
    .box_img1 {
      position: relative;

      /deep/ .dg {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 38px;
        color: #FF8201;
      }

      width: 134px;
      height: 75px;
      margin-right: 10px;
      //

      img {
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        border: 2px solid #FF8201;
      }
    }

    // 没有打钩的样式
    .box_img2 {
      position: relative;

      .dg {
        display: none;
      }

      width: 134px;
      height: 75px;
      margin-right: 10px;

      img {
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        border: 2px solid #fff;
      }
    }
  }

  .el-form {

    .imgCenter {

      .imgList {
        margin-left: 50px;
        display: flex;
        align-items: end;
        margin-bottom: 15px;

        .box_img1 {
          cursor: pointer;
          border: 2px solid #3489ff;
          background: #000;
          width: 50px;
          height: 50px;
          margin-right: 10px;
          //

          img {
            position: relative;
            z-index: 2;
            width: 50px;
            height: 50px;
            opacity: 0.6;
          }
        }

        // 没有打钩的样式
        .box_img2 {
          cursor: pointer;
          border: 2px solid #fff;
          width: 50px;
          height: 50px;
          margin-right: 10px;

          img {
            position: relative;
            z-index: 2;
            width: 50px;
            height: 50px;
          }
        }

      }

    }

    //图片上传样式
    .upImg {
      display: flex;

      // 上传图片样式


      //添加一张图片之后隐藏后续框样式
      .disabled .el-upload--picture-card {
        display: none;
      }
    }
  }
}

.setImg {
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  .imgList {
    width: 182px;
    display: flex;
    align-items: end;
    margin: 0 auto;
    flex-wrap: wrap;

    // 没有打钩的样式
    .box_img2 {
      cursor: pointer;
      border: 2px solid #fff;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        border: 2px solid #fff;
        border-radius: 4px;
        position: relative;
        z-index: 2;
        width: 50px;
        height: 50px;
      }
    }

    .box_img1 {
      cursor: pointer;
      border: 2px solid #fff;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        border: 2px solid #FF8201;
        border-radius: 4px;
        position: relative;
        z-index: 2;
        width: 50px;
        height: 50px;
        opacity: 0.6;
      }
    }
  }

  .avatar-uploader {
    padding: 0 24px;
    position: relative;

    //  上传图片样式
    .avatar {
      width: 152px;
      height: 152px;
      box-sizing: border-box;

      img {
        width: 152px;
        height: 152px;
        border-radius: 4px;
      }
    }

    //没上传图片样式
    .jh {
      width: 152px;
      height: 152px;
      box-sizing: border-box;
      border: 2px dashed #ebebeb;

      .avatar-uploader-icon {
        font-size: 50px;
        margin-top: 43px;
      }
    }
  }
}

/deep/.progress-position {
  left: 24px !important;
}

/deep/.el-progress-circle {
  width: 152px !important;
  height: 152px !important;
}

/deep/.el-form-item__label {
  padding: 0;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

/deep/.el-input.is-disabled .el-input__inner {
  background: #F7FBFF;
  border: 1px solid #DCE7F3;
  font-size: 16px;
  color: #333;
}

.head-border {
  width: 100%;
  height: 120px;
  border: 1px solid #fff;
  border-width: 1px 1px 0 1px;
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.baseInfo-main {
  position: relative;
  z-index: 1;
  /deep/.el-form-item__label {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
